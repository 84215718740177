/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import HeaderLanding from "./header-landing"
import FooterLanding from "./footer-landing"

const LayoutLanding = ({ children }) => {
  return (
    <>
      <HeaderLanding siteTitle={`Title`} />
      <div>
        <main>{children}</main>
      </div>
      <FooterLanding />
    </>
  )
}

LayoutLanding.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutLanding
