import * as React from 'react'
import { navigate } from 'gatsby'
import splashPageHeroJohnGoncalves from '../images/home-page/home-page-hero-john-goncalves.webp'

import Cookies from 'universal-cookie';

const handleContinueToWebsiteClick = e => {
  const cookies = new Cookies()

  e.preventDefault();

  cookies.set('splash-page-state', 'clicked-continue-to-website', {
    maxAge: 86400,
    path: '/'
  }); // Max-Age = 86400 = 1 day

  navigate('/');
}


export default function SplashPageHero() {
  return (
    <div className="flex flex-col-reverse lg:flex-col relative text-white">
      <div className="flex-none mx-auto max-w-7xl w-full pt-10 pb-10 text-center lg:text-left">
        <div className="px-4 xl:px-0 lg:w-1/2 xl:pr-16">
          <h1 className="text-3xl tracking-tight font-bold sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl">
            <span className="block xl:inline"><span className="text-aqua-headline">Become a Founding Donor Now</span></span>
          </h1>
          <div className="home-page-hero-mailchimp-success">
            <p className="max-w-md mx-auto text-xl sm:text-2xl md:max-w-3xl">
              John Goncalves is running for Congress to represent RI-01 in 2023.
            </p>
            <p className="max-w-md mx-auto font-bold text-xl sm:text-2xl md:max-w-3xl">
              Will you show John he has your support and become a Founding Donor today?
            </p>
            <div className="home-page-hero-mailchimp-success-donation-ctas mt-5 mb-5">
              <div className="grid gap-5 grid-cols-2 sm:grid-cols-4">
                <a
                    href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-splash-page-hero&amount=10"
                    type="button"
                    className="py-3 border border-transparent text-center text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    $10
                </a>
                <a
                    href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-splash-page-hero&amount=35"
                    type="button"
                    className="py-3 border border-transparent text-center text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    $35
                </a>
                <a
                    href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-splash-page-hero&amount=50"
                    type="button"
                    className="py-3 border border-transparent text-center text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    $50
                </a>
                <a
                    href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-splash-page-hero&amount=100"
                    type="button"
                    className="py-3 border border-transparent text-center text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    $100
                </a>
                <a
                    href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-splash-page-hero&amount=500"
                    type="button"
                    className="py-3 border border-transparent text-center text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    $500
                </a>
                <a
                    href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-splash-page-hero&amount=1000"
                    type="button"
                    className="py-3 border border-transparent text-center text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    $1,000
                </a>
                <a
                    href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-splash-page-hero&amount=3300"
                    type="button"
                    className="py-3 border border-transparent text-center text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    $3,300
                </a>
                <a
                    href="https://secure.actblue.com/donate/john-goncalves-website-donate?refcode=website-splash-page-hero"
                    type="button"
                    className="py-3 border border-transparent text-center text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    OTHER
                </a>
              </div>
            </div>
            <aside>
              By clicking the links above, you certify that you are responsible for paying all charges incurred in using the debit or credit card to be charged, and that your personal funds will be the true source of the contribution.
            </aside>
            <a
              href="/"
              className="mt-10 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xl sm:text-2xl font-medium font-bold text-white bg-blue-buttonLight hover:bg-blue-700"
              onClick={handleContinueToWebsiteClick}
            >
              Continue to Website &raquo;
            </a>
          </div>
        </div>
      </div>
      <div className="flex-none relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={splashPageHeroJohnGoncalves}
          alt=""
        />
      </div>
    </div>
  )
}
