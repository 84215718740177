import * as React from "react"

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
  export default function FooterLanding() {
    return (
      <footer className="bg-blue-bgDark" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 xl:px-0 lg:py-16 lg:px-8">
          <div className="border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
            <p className="text-lg text-gray-400 md:order-1">
              <span className="table sm:inline-block border p-2 mr-2 mb-2">Paid for by John Goncalves for Congress</span>&copy; 2023. All rights reserved. | <a className="text-gray-300 hover:text-white" href="/privacy-policy">Privacy Policy</a>
            </p>
          </div>
        </div>
      </footer>
    )
  }
