import * as React from "react"
import { Popover } from '@headlessui/react'
import logoBlue from '../images/logo-blue.webp'

export default function HeaderLanding() {
  return (
    <Popover className="top-0 bg-blue-bgDark z-10">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <span className="sr-only">Elect John Goncalves for Congress (RI-01) - Putting Our Community First</span>
              <img
                className="w-4/4 sm:w-3/4 md:w-2/4 lg:w-1/4"
                src={logoBlue}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>
    </Popover>
  )
}
