import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import LayoutLanding from "../components/layout-landing"
import Seo from "../components/seo"
import SplashPageHero from "../components/splash-page-hero"

const SplashPage = ({ location }) => (
  <LayoutLanding >
    <Seo location={location} canonicalPathname="/" title="Become a Founding Donor" />
    <SplashPageHero />
  </LayoutLanding>
)

export default SplashPage
